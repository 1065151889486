<template>
  <form @submit.prevent="submitChangeInformation" class="w-full pb-4">
    <h2 class="px-6 py-4 font-bold text-lg">Personal Information</h2>
    <div class="m-auto border-b"></div>
    <div class="px-6 pb-3 pt-8">
      <div class="w-full form__div">
        <input
          class="form__input"
          type="text"
          id="first_name"
          placeholder=" "
          v-model="firstName"
        />
        <label for="first_name" class="form__label">First Name</label>
      </div>
      <div class="w-full form__div">
        <input
          class="form__input"
          type="text"
          id="last_name"
          placeholder=" "
          v-model="lastName"
        />
        <label for="last_name" class="form__label">Last Name</label>
      </div>
      <div class="w-full form__div">
        <input
          class="form__input opacity-50"
          type="email"
          id="email"
          placeholder=" "
          v-model="email"
          disabled
        />
        <label for="email" class="form__label">Email</label>
      </div>
      <div class="w-full form__div">
        <input
          class="form__input"
          type="text"
          id="phoneNumber"
          placeholder=" "
          v-model="phoneNumber"
          required
        />
        <label for="phoneNumber" class="form__label">Phone number</label>
      </div>
    </div>
    <div class="m-auto border-b"></div>
    <div class="p-6 bg-white py-2 flex justify-between items-center">
      <div class="w-full">
        <h3 class="text-lg font-bold text-color mb-1">Notifications</h3>
        <div class="flex justify-between items-center">
          <span class="text-gray-400 flex-grow">
            Opt In for Mastermind text messaging for important training updates
          </span>
          <input
            class="ml-4 cursor-pointer"
            type="checkbox"
            v-model="acceptedOptIn"
          />
        </div>
      </div>
    </div>
    <div class="m-auto border-b"></div>
    <div class="p-6 bg-white py-2 flex justify-between items-end">
      <div>
        <h3 class="text-lg font-bold text-color mb-1">Password</h3>
        <p class="text-gray-400">Change your password</p>
      </div>
      <button
        type="button"
        class="text-blue-500 underline hover:text-blue-700 focus:outline-none"
        @click="onChangePassword"
      >
        Change password
      </button>
    </div>
    <div class="m-auto border-b"></div>
    <h3 class="px-6 pt-2 text-lg font-bold text-color mb-1">Subscription</h3>
    <div v-if="isLoading" class="flex justify-center items-center pb-4">
      <em class="fa fa-spinner fa-spin"></em>
    </div>
    <div
      v-else-if="
        subscriptionPlatform === 'Stripe' &&
        isSubscriptionSuspended &&
        subscriptionExpiryDate &&
        !isSubscriptionExpired &&
        cancelationReason !== 'payment_failed'
      "
    >
      <div class="bg-gray-100 p-4 mx-5 mb-3 rounded-lg">
        <div class="flex items-center space-x-4">
          <img src="@/assets/icons/Info.svg" alt="Info" class="h-6 w-6" />
          <p class="text-gray-400 text-sm">
            This subscription profile has been suspended. The user and the
            associated players will have access to the Mastermind VR experience
            until
            <span class="font-bold">{{ subscriptionExpiryDate }}.</span>
            After this date, you can reactivate the subscription by pressing the
            "Activate Subscription" button.
          </p>
        </div>
      </div>
      <div class="p-6 bg-white pb-2 pt-0 flex justify-end items-end"></div>
    </div>
    <div
      v-else-if="
        (subscriptionPlatform === 'Stripe' &&
          isSubscriptionSuspended &&
          subscriptionExpiryDate &&
          isSubscriptionExpired) ||
        (isSubscriptionSuspended && cancelationReason === 'payment_failed')
      "
      class="px-6 bg-white pb-2 flex justify-between items-end"
    >
      <div>
        <p class="text-gray-400">This subscription is currently suspended.</p>
      </div>
      <button
        type="button"
        class="text-blue-500 underline hover:text-blue-700 focus:outline-none"
        @click="handleActivation"
      >
        Activate subscription
      </button>
    </div>
    <div
      v-else-if="
        (subscriptionPlatform === 'Apple' || subscriptionPlatform === 'Google') &&
        isSubscriptionSuspended &&
        subscriptionExpiryDate
      "
    >
      <div class="bg-gray-100 p-4 mx-5 mb-3 rounded-lg">
        <div class="flex items-center space-x-4">
          <img src="@/assets/icons/Info.svg" alt="Info" class="h-6 w-6" />
          <p class="text-gray-400 text-sm">
            This subscription profile has been suspended. The user and the
            associated players will have access to the Mastermind VR experience
            until
            <span class="font-bold">{{ subscriptionExpiryDate }}.</span>
            You can reactivate the subscription by pressing the "Activate
            Subscription" button.
          </p>
        </div>
      </div>
      <div class="p-6 bg-white pb-2 pt-0 flex justify-end items-end">
        <button
          type="button"
          class="text-blue-500 underline hover:text-blue-700 focus:outline-none"
          @click="handleActivation"
        >
          Activate subscription
        </button>
      </div>
    </div>
    <div
      v-else-if="isSubscriptionSuspended === false"
      class="px-6 bg-white pb-2 flex justify-between items-end"
    >
      <div>
        <p class="text-gray-400">Manage your Subscription</p>
      </div>
      <button
        type="button"
        class="text-blue-500 underline hover:text-blue-700 focus:outline-none"
        @click="showCancelModal = true"
      >
        Suspend subscription
      </button>
    </div>
    <div v-else class="bg-gray-100 p-4 mx-5 mb-3 rounded-lg">
      <div class="flex items-center space-x-3">
        <img src="@/assets/icons/Info.svg" alt="Info" class="h-6 w-6" />
        <p class="text-gray-400 text-sm">No active subscription found</p>
      </div>
    </div>
    <div class="m-auto border-b"></div>
    <div class="px-6 pt-4 w-full">
      <div>
        <button
          class="text-color submit-btn font-bold px-4 py-2 w-full"
          type="submit"
        >
          Save changes
        </button>
      </div>
    </div>
  </form>
  <CancelSubscriptionModal
    :show="showCancelModal"
    :subscriptionExpiryDate="subscriptionExpiryDate"
    @update:show="showCancelModal = $event"
    @confirm="handleCancelFullSubscription"
    :isLoading="isLoadingCancelSubscription"
  />
  <ActivateSubscriptionOptionModal
    :show="showActivateModal"
    :subscriptionExpiryDate="subscriptionExpiryDate"
    :provider="subscriptionPlatform"
    @update:show="showActivateModal = $event"
    @confirm="handleActivateSelectedOption"
  />
  <ActivateFullSubscriptionModal
    :show="showFullActivateModal"
    :subscriptionExpiryDate="subscriptionExpiryDate"
    @update:show="showFullActivateModal = $event"
    @confirm="handleActivateFullSubscription"
    :isLoading="isLoadingInvoice"
  />
  <SelectDependentsModal
    :show="showDependentsModal"
    :subscriptionExpiryDate="subscriptionExpiryDate"
    @update:show="showDependentsModal = $event"
    @confirm="handleActivatePartialSubscription"
    :isLoading="isLoadingInvoice"
    :players="players"
  />
  <PaymentInformationModal
    :show="showPaymentModal"
    :subscriptionExpiryDate="subscriptionExpiryDate"
    @update:show="showPaymentModal = $event"
    @confirm="handlePaymentMethod"
    :isLoading="isLoadingPayment"
    confirmationText="Restore subscription"
  />
  <ConfirmPaymentModal
    :show="showConfirmPaymentModal"
    :subscriptionExpiryDate="subscriptionExpiryDate"
    :newSubscriptionId="newSubscriptionId"
    @update:show="showConfirmPaymentModal = $event"
    @confirm="handleConfirmPayment"
    :isLoading="isLoadingConfirmPayment"
  />
  <InformationModal
    :show="showCongratulationModal"
    :subscriptionExpiryDate="subscriptionExpiryDate"
    @update:show="showCongratulationModal = $event"
    @confirm="showCongratulationModal = false"
    :title="'Activate subscription'"
    :subtitle="'Congratulations!'"
    :content="getCongratulationModalContent()"
  />
  <InformationModal
    :show="showIAPActivateModal"
    @update:show="showIAPActivateModal = $event"
    @confirm="showIAPActivateModal = false"
    :title="'Activate subscription'"
    :content="getIAPActivateModalContent()"
  />
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";
import { expressions } from "@/components/general/utils";
import { useStore } from "vuex";
import {
  editUser,
  getProfiles,
  getSubscriptionProvider,
} from "@/services/user/user";
import {
  cancelFullSubscription,
  getStripeSubscriptionInfo,
  reactiveSubscription,
  confirmSubscriptionReactivation,
  attachPaymentMethod,
} from "@/services/stripe/stripe.js";
import {
  getAppStoreSubscriptionInfo,
  inAppPurchaseDeactivateUsers,
  inAppPurchasedependentsToAddOrReactivate,
  getGooglePlayStoreSubscriptionInfo,
} from "@/services/inAppPurchase/inAppPurchase.js";
import { formatPhoneNumber } from "@/utils/formatPhoneNumber.js";
import CancelSubscriptionModal from "@/components/profile/modals/CancelSubscriptionModal.vue";
import ActivateSubscriptionOptionModal from "@/components/profile/modals/ActivateSubscriptionOptionModal.vue";
import ActivateFullSubscriptionModal from "@/components/profile/modals/ActivateFullSubscriptionModal.vue";
import SelectDependentsModal from "@/components/profile/modals/SelectDependentsModal.vue";
import PaymentInformationModal from "@/components/profile/modals/PaymentInformationModal.vue";
import ConfirmPaymentModal from "@/components/profile/modals/ConfirmPaymentModal.vue";
import InformationModal from "@/components/profile/modals/InformationModal.vue";
import stripe from "@/plugins/stripe";

const store = useStore();
const user = computed(() => store.getters["user/getUserData"]);
const router = useRouter();
const toast = useToast();

const firstName = ref(user.value.firstName);
const lastName = ref(user.value.lastName);
const email = ref(user.value.email);
const phoneNumber = ref(user.value?.phoneNumber?.replace(/^\+1/, "") || "");
const acceptedOptIn = ref(user.value.guardianOptIn);
const showCancelModal = ref(false);
const showActivateModal = ref(false);
const showFullActivateModal = ref(false);
const showDependentsModal = ref(false);
const showPaymentModal = ref(false);
const showConfirmPaymentModal = ref(false);
const showCongratulationModal = ref(false);
const players = ref([]);
const selectedDependentsIds = ref([]);
const newSubscriptionId = ref("");
const paymentIntentId = ref("");
const isSubscriptionSuspended = ref(false);
const subscriptionExpiryDate = ref("");
const cancelationReason = ref("");
const isSubscriptionExpired = ref(false);
const isLoading = ref(true);
const isLoadingCancelSubscription = ref(false);
const isLoadingPayment = ref(false);
const isLoadingConfirmPayment = ref(false);
const isLoadingInvoice = ref(false);
const subscriptionPlatform = ref("Stripe");
const showAppleSubscriptionModal = ref(false);
const availableSeats = ref(0);
const showIAPActivateModal = ref(false);

onMounted(async () => {
  try {
    const subscriptionProvider = await getSubscriptionProvider();

    if (subscriptionProvider === "Stripe") {
      subscriptionPlatform.value = subscriptionProvider;
      let stripeSubscriptionInfo;
      try {
        stripeSubscriptionInfo = await getStripeSubscriptionInfo();
      } catch (error) {
        stripeSubscriptionInfo = {
          subscription_end_date: "",
          subscription_status: "error",
          is_expired: true,
          cancelation_reason: "Failed to fetch subscription info",
        };
      }
      await handleSubscription(stripeSubscriptionInfo, subscriptionProvider);
    } else if (subscriptionProvider === "Apple") {
      subscriptionPlatform.value = subscriptionProvider;
      const appStoreTransactionInfo = await getAppStoreSubscriptionInfo();
      await handleSubscription(appStoreTransactionInfo, subscriptionProvider);
    } else if (subscriptionProvider === "Google") {
      subscriptionPlatform.value = subscriptionProvider;
      const googlePlayStoreTransactionInfo = await getGooglePlayStoreSubscriptionInfo();
      await handleSubscription(googlePlayStoreTransactionInfo, subscriptionProvider);
    } else {
      subscriptionExpiryDate.value = "";
      isSubscriptionSuspended.value = true;
      cancelationReason.value = "";
      isSubscriptionExpired.value = true;
    }

    players.value = await getProfiles();
  } catch (error) {
    subscriptionExpiryDate.value = "";
    isSubscriptionSuspended.value = true;
    cancelationReason.value = "";
    isSubscriptionExpired.value = true;
  } finally {
    isLoading.value = false;
  }
});

async function handleSubscription(subscription, platform) {
  subscriptionExpiryDate.value = subscription.subscription_end_date;
  isSubscriptionSuspended.value = subscription.subscription_status !== "active";
  cancelationReason.value =
    platform === "Stripe" ? subscription.cancelation_reason : "";
  isSubscriptionExpired.value = subscription.is_expired;
}

async function submitChangeInformation() {
  try {
    if (
      !expressions.letters.test(firstName.value.trim()) ||
      !expressions.letters.test(lastName.value.trim())
    ) {
      return handleMessage(
        "The first and last name fields are text only!",
        true
      );
    }

    await submitRequest();

    store.commit("user/setFullName", {
      firstName: firstName.value,
      lastName: lastName.value,
    });
    store.commit("user/setEmail", email.value);
    store.commit("user/setPhoneNumber", phoneNumber.value);
    store.commit("user/setGuardianOptIn", acceptedOptIn.value);

    handleMessage("The profile has been updated!", false);
  } catch (error) {
    handleMessage(error.message, true);
  }
}

async function submitRequest() {
  await editUser(user.value.id, {
    first_name: firstName.value,
    last_name: lastName.value,
    phone_number: `+1${phoneNumber.value}`,
    guardian_opt_in_status: acceptedOptIn.value,
  });
}

function handleMessage(message, error) {
  error ? toast.error(message) : toast.success(message);
}

async function handleCancelFullSubscription() {
  try {
    isLoadingCancelSubscription.value = true;
    if (subscriptionPlatform.value === "Apple" || subscriptionPlatform.value === "Google") {
      const dependentsIds = players.value.map((player) => player.id);
      await inAppPurchaseDeactivateUsers(dependentsIds, true, subscriptionPlatform.value);
    } else {
      await cancelFullSubscription();
    }
    isSubscriptionSuspended.value = true;
    handleMessage("The subscription was successfully canceled", false);
  } catch (error) {
    handleMessage(error.message, true);
  } finally {
    showCancelModal.value = false;
    isLoadingCancelSubscription.value = false;
  }
}

function handleActivation() {
  if (subscriptionPlatform.value === "Apple" || subscriptionPlatform.value === "Google") {
    showIAPActivateModal.value = true;
  } else if (subscriptionPlatform.value === "Stripe") {
    showActivateModal.value = true;
  }
}

function handleActivateSelectedOption(selectedOption) {
  showActivateModal.value = false;
  if (selectedOption === "custom") {
    showDependentsModal.value = true;
  } else {
    showFullActivateModal.value = true;
  }
}

async function handleActivatePartialSubscription(dependentsIds) {
  selectedDependentsIds.value = dependentsIds;
  await handleReactiveSubscription(dependentsIds);
}

async function handleActivateFullSubscription() {
  const dependentsIds = players.value.map((player) => player.id);
  selectedDependentsIds.value = dependentsIds;
  await handleReactiveSubscription(dependentsIds);
}

async function handleReactiveSubscription(dependentsIds) {
  isLoadingInvoice.value = true;

  try {
    const response = await reactiveSubscription(dependentsIds);
    paymentIntentId.value = response.payment_intent_id;
    newSubscriptionId.value = response.subscription_id;

    showDependentsModal.value = false;
    showFullActivateModal.value = false;
    showConfirmPaymentModal.value = true;
  } catch (error) {
    if (error.code === 400) {
      showPaymentModal.value = true;
    }
    handleMessage(error.message, true);
  } finally {
    isLoadingInvoice.value = false;
  }
}

async function handleConfirmPayment() {
  isLoadingConfirmPayment.value = true;

  try {
    await confirmSubscriptionReactivation(
      paymentIntentId.value,
      newSubscriptionId.value,
      selectedDependentsIds.value
    );
    await handleSubscription(await getStripeSubscriptionInfo(), "Stripe");

    showConfirmPaymentModal.value = false;
    showCongratulationModal.value = true;
  } catch (error) {
    if (error.code === 400) {
      showPaymentModal.value = true;
    }
    handleMessage(error.message, true);
    showConfirmPaymentModal.value = false;
  } finally {
    isLoadingConfirmPayment.value = false;
  }
}

async function handlePaymentMethod({ card, name }) {
  isLoadingPayment.value = true;
  const response = await stripe.createPaymentMethod({
    type: "card",
    card: card,
    billing_details: {
      name: name,
    },
  });

  try {
    await attachPaymentMethod(response.paymentMethod.id);
    await handleReactiveSubscription(selectedDependentsIds.value);

    showPaymentModal.value = false;
  } catch (error) {
    handleMessage(error.message, true);
  } finally {
    isLoadingPayment.value = false;
  }
}

function onChangePassword() {
  router.push({ name: "Change Password" });
}

function getCongratulationModalContent() {
  return `
    Your account has been successfully activated, all the users associated to this 
    account now have full access to all the Mastermind Cognitive Training experience.
  `;
}

function getIAPActivateModalContent() {
  return `
    Remember to reactivate or update your subscription in your ${subscriptionPlatform.value === "Apple" ? "Apple ID settings" : "Mastermind app settings"},
    then make sure to add or update your players in the players settings view.
  `;
}

watch(
  () => phoneNumber.value,
  (newValue, oldValue) => {
    const formatted = formatPhoneNumber(newValue);
    if (newValue !== formatted) {
      phoneNumber.value = formatted;
    }
  }
);
</script>
